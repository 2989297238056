import React from "react"
import styled from "styled-components"
import slugify from "slugify"
import Img from "react-cloudinary-lazy-image"
import { Image } from "theme-ui"

const Hero = ({
  pageTitle,
  overlayPercentage = 0.3,
  subHeading,
  pageHero,
  hcHero,
}) => {
  if (pageTitle === "Welcome to dockside igloo park") {
  }

  const getCustom = () =>
    pageTitle === "Welcome to dockside igloo park"
      ? "reserve-an-igloo"
      : slugify(pageTitle, {
          lower: true,
        })

  const formattedPageTitle = pageTitle.replace("-", " ")
  return (
    <HeroContainer>
      <DarkenOverlay percentage={overlayPercentage} />
      {pageHero || hcHero ? (
        <Image src={hcHero || pageHero} alt={pageTitle} />
      ) : (
        <Img
          cloudName={"gonation"}
          imageName={`${
            pageTitle === "beer list"
              ? "sites/dockside-brewing/double-image-left.jpg"
              : `sites/dockside-brewing/${getCustom()}-hero.jpg`
          }`}
          fluid={{
            maxWidth: 1900,
            // height: 700,
            // objectPosition: 'bottom!important'
          }}
          version="v3"
          style={{
            width: "100%",
            // height: "100%",
            // objectFit: 'cover',
            // background: 'rgba(0,0,0,.9)',
            objectPosition: "bottom!important",
            objectFit: "cover",
            // backgroundPosition: 'bottom'
          }}
        />
      )}

      {pageTitle === "Welcome to dockside igloo park" ? (
        // <a
        //   className="open-table-btn-container"
        //   target="_blank"
        //   href="https://www.opentable.com/booking/experiences-availability?rid=1085665&restref=1085665&experienceId=108057"
        // >
        //   <span className="">
        //     Book an Igloo Here
        //   </span>
        // </a>
        <></>
      ) : (
        ""
      )}
      <TitleContainer>
        {pageTitle !== "MILFORD OKTOBERFEST 2021" && (
          <PageTitle subHeading={subHeading}>{formattedPageTitle}</PageTitle>
        )}
        {pageTitle === "MILFORD OKTOBERFEST 2021" ? (
          <PageTitleOktoberfest subHeading={subHeading}>
            {formattedPageTitle}
          </PageTitleOktoberfest>
        ) : (
          ""
        )}
        {subHeading && (
          <PageSubHeading>
            <p>{subHeading}</p>
          </PageSubHeading>
        )}
      </TitleContainer>
    </HeroContainer>
  )
}

const PageSubHeading = styled.h3`
  font-size: 1rem;
  color: #fff;
  padding: 0.5rem 1.5rem;
  text-align: center;
  position: absolute;
  bottom: 25px;
  z-index: 99;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;

  p {
    max-width: 600px;
    margin: auto;
  }
`

const TitleContainer = styled.div`
  width: 100%;
`

const HeroContainer = styled.section`
  background: rgba(0, 0, 0, 0.9);
  height: 550px;
  display: flex;
  width: 100%;
  background-position: center;
  background-size: cover;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  @media (min-width: 1024px) {
    /* height: 700px; */
  }

  img {
    object-fit: cover;
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    /* object-position: bottom!important; */
  }
`

const PageTitle = styled.h1`
  color: white;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: ${({ subHeading }) => (subHeading ? "8rem" : "2rem")};
  z-index: 2;
  @media min-width(1024px) {
    font-size: 3.5rem;
  }
`

const PageTitleOktoberfest = styled.h1`
  color: white;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: ${({ subHeading }) => (subHeading ? "8rem" : "2rem")};
  z-index: 2;
  @media min-width(1024px) {
    font-size: 3.5rem;
  }
`

const DarkenOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${({ percentage }) => `rgba(0,0,0,${percentage})`};
`

export default Hero
