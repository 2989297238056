import React from "react"
import styled from "styled-components"
import Hamburger from "../icons/hamburger"
import Close from "../icons/close"

import getGoogleStr from "../../helpers/getGoogleStr"

export default function CTAWidget({ navIsOpen, setNavIsOpen, siteMetaData }) {
  const { name, street, city, zip, state, phone } = siteMetaData
  return (
    <Container className="is-hidden-desktop">
      <Columns className="columns is-mobile is-vcentered">
        <Column className="column">
          <CTALinks href={`tel:${phone}`} target="_blank" rel="noopener">
            Call
          </CTALinks>
        </Column>
        <Column className="column">
          <CTALinks
            href={getGoogleStr(name, street, city, zip, state)}
            target="_blank"
            rel="noopener"
          >
            Directions
          </CTALinks>
        </Column>
        <Column className="column">
          <CTALinks
            href="https://www.opentable.com/r/dockside-brewery-milford"
            target="_blank"
            rel="noopener"
          >
            Reserve
          </CTALinks>
        </Column>
        <Column className="column">
          <HamburgerContainer
            className="is-hidden-desktop"
            onClick={() => setNavIsOpen(!navIsOpen)}
          >
            {navIsOpen ? (
              <Close width="20px" fill="#fff" />
            ) : (
              <Hamburger fill="#fff" width="20px" />
            )}
          </HamburgerContainer>
        </Column>
      </Columns>
    </Container>
  )
}

const Container = styled.section`
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  padding: 0.75rem 0.5rem;
  width: 100%;
  border-top: 2px solid white;
`

const Columns = styled.div``

const Column = styled.div`
  text-align: center;
  background: ${props =>
    props.theme.secondary ? props.theme.secondary : "black"};
  &:last-child {
    background: ${props =>
      props.theme.primary ? props.theme.primary : "black"};
    padding: 14px 0;
  }
  &:nth-child(3) {
    border-right: 2px solid white;
  }
`

const CTALinks = styled.a`
  color: white;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
`

const HamburgerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
