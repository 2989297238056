import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
export default function FooterMap() {
  return (
    <Container className="footer-map">
      <FooterLink>
        <Link to="/">Home</Link>
      </FooterLink>
      <FooterLink>
        <Link to="/about">About</Link>
      </FooterLink>
      <FooterLink>
        <Link to="/brewery">The Beer</Link>
      </FooterLink>
      <FooterLink>
        <Link to="/menu">Food</Link>
      </FooterLink>
      <FooterLink>
        <Link to="/drink">Drinks</Link>
      </FooterLink>
      <FooterLink>
        <Link to="/events">Events</Link>
      </FooterLink>
      <FooterLink>
        <Link to="/oktoberfest">Oktoberfest</Link>
      </FooterLink>
      {/* <FooterLink>
        <Link to="/mug-club">Mug Club</Link>
      </FooterLink> */}
      <FooterLink>
        <Link to="/gallery">Gallery</Link>
      </FooterLink>
      <FooterLink>
        <Link to="/contact">Contact</Link>
      </FooterLink>
      <FooterLink>
        <Link to="/faq">FAQ</Link>
      </FooterLink>
      <FooterLink>
        <Link to="/tour-the-venue">
          <span>Tour the Venue</span>
        </Link>
      </FooterLink>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: #eee;
  padding: 1.5rem 0;
  @media (min-width: 1024px) {
    justify-content: center;
    align-items: center;
    padding: 3rem 1.5rem;
  }
`

const FooterLink = styled.div`
  width: 50%;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.75rem;
  @media (min-width: 1024px) {
    width: auto;
    padding: 0 1rem;
    margin: 0;
  }
  a {
    color: ${props => (props.theme.primary ? props.theme.primary : "black")};
    text-transform: uppercase;
    &:hover {
      color: ${props =>
        props.theme.secondary ? props.theme.secondary : "black"};
    }
  }
`
