import React, { useState, useEffect } from "react"
import styled from "styled-components"
import cloudinaryOptimize from "../helpers/cloudinaryOptimize"
import logoWhite from "../assets/logo-white.png"

import Beers from "../components/icons/Beers"
import Peace from "../components/icons/Peace"

const AgeConfirmationWrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: hidden;
  background: black;
  width: 100%;
  padding: 2rem 1rem;
  z-index: 9999999;
  background: url("${cloudinaryOptimize(
    "https://res.cloudinary.com/gonation/image/upload/sites/dockside-brewing/age-restriction-bg.jpg",
    800
  )}");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0 !important;
`

const Container = styled.div`
  background: #01486994;
  color: white;
  padding: 1.5rem 2rem;
  max-width: 600px;
  margin: auto;

  border: 4px solid #00567d;

  @media (max-width: 768px) {
    margin-top: 50px;
  }
  border-radius: 13px;
  padding-bottom: 0 !important;
  p {
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
  }
`

const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  padding-top: 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    align-items: stretch;
  }
`

const Button = styled.button`
  border: 1px solid white;
  background: white;
  padding: 1rem 2rem;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  border: none;
  cursor: pointer;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  &:hover {
    transition: all 0.3s;
  }

  ${({ type, theme }) => {
    if (type === "yes") {
      return `background: ${theme.primary}`
    }
  }};

  ${({ type, theme }) => {
    if (type === "no") {
      return `background: ${theme.secondary}`
    }
  }};
  justify-content: center;
  width: 100%;
  margin-bottom: 1.5rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    /* padding: 1rem 4rem; */
    margin-bottom: 0;
    width: auto;
    padding: 1rem 0;
    width: 250px;
  }
`

const Message = styled.p`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-top: 2rem;
  text-align: center;
`

const AgeConfirmation = ({ onClick }) => {
  const [message, setMessage] = useState("")

  const handleYesClick = () => {
    onClick("true")
    localStorage.setItem("isOfAge", true)
  }

  const handleNoClick = () => {
    localStorage.setItem("isOfAge", false)
    setMessage(
      "You are not of legal drinking age, but we encourage you to come back when you are. Thank you for visiting."
    )
  }

  return (
    <AgeConfirmationWrapper>
      <Container>
        <div>
          <img src={logoWhite} alt="Dockside Brewing" />
        </div>
        <p>Are you at least 21 years old?</p>
        {message === "" ? (
          <BtnContainer>
            <div>
              <Button onClick={() => handleYesClick()} type="yes">
                YES, I'M 21+{" "}
                <span style={{ paddingLeft: "1rem" }}>
                  <Beers width="30px" />
                </span>{" "}
              </Button>
            </div>
            <div>
              <Button type="no" onClick={() => handleNoClick()}>
                NOT YET{" "}
                <span style={{ paddingLeft: "1rem" }}>
                  <Peace width="30px" />
                </span>
              </Button>
            </div>
          </BtnContainer>
        ) : (
          ""
        )}

        <Message>{message}</Message>
      </Container>
    </AgeConfirmationWrapper>
  )
}

export default AgeConfirmation
