import React from "react"
import styled from "styled-components"
import FooterMap from "./FooterMap"
import FooterContent from "./FooterContent"
import FooterLogo from "./FooterLogo"
import BadgeComponent from "../BadgeComponent"

const Footer = ({ data }) => {
  return (
    <FooterContainer>
      <FooterContent data={data} />
      <FooterMap />

      <Fineprint>
        Dockside is 21 and over unless accompanied by a parent or guardian.
      </Fineprint>
      <BadgeComponent />

      <FooterLogo name={data.name} />
    </FooterContainer>
  )
}

const FooterContainer = styled.footer`
  padding-bottom: 90px;
  background-color: white;
`

const Fineprint = styled.h3`
  padding: 2rem 1rem;
  font-size: 1.5rem;
  text-align: center;
  background: ${({ theme }) => theme.secondary};
  color: white;
`

export default Footer
