import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Close from "../components/icons/close"

const EventsPopupModal = ({ setShowPopup }) => {
  return (
    <Popup>
      <Box>
        <Container onClick={() => setShowPopup(false)}>
          <Close width="20px" fill="#000" />
        </Container>

        <Flex>
          {/* <Btn>
            <Link to="/oktoberfest">Oktoberfest</Link>
          </Btn> */}
          <Btn>
            <Link to="/specials">All Specials</Link>
          </Btn>
        </Flex>
      </Box>
    </Popup>
  )
}

const Container = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 89;
`

const Btn = styled.button`
  padding: 2rem 4rem;
  background: ${({ theme }) => theme.primary};
  box-shadow: none;
  border: none;
  color: white;
  width: 100%;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.primary};
  &:hover {
    background: transparent;
    color: black !important;
    transition: all 0.3s;
    a {
      color: black;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
  a {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.75rem;
    &:hover {
      color: black !important;
    }
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const Box = styled.div`
  background: white;
  border-radius: 13px;
  padding: 2rem;
  width: 600px;
  position: relative;
`

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
`

export default EventsPopupModal
