import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import MobileLogo from "../assets/logo-white.png"
import LogoMobile from "../assets/logo-girl.png"
import Footer from "./Footer/Footer"
import DesktopShoutBanner from "./DesktopShoutBanner"
import Hero from "./Hero"
import Navigation from "./Navigation"
import SEO from "./SEO"
import "../styles/index.scss"
import "react-image-lightbox/style.css"
import CTAWidget from "./widgets/CTAWidget"
import AgeConfirmation from "./AgeConfirmation"
import cloudinaryOptimize from "../helpers/cloudinaryOptimize"

const Layout = ({
  children,
  pageTitle,
  hasTopShoutBanner = false,
  subHeading,
  noHero,
  noFooter,
  cta,
  noNav,
  pageHero,
  hcHero,
}) => {
  const { siteMetaData } = useStaticQuery(graphql`
    query LayoutQuery {
      siteMetaData {
        city
        cover {
          imageBaseUrl
          imagePrefix
          isDefault
        }
        avatar {
          isDefault
          imagePrefix
          imageBaseUrl
        }
        desc
        links {
          facebook
          instagram
          twitter
          website
          youtube
        }
        loc
        name
        phone
        slug
        state
        street
        zip
      }
    }
  `)

  const [navBackground, setNavBackground] = useState(false)
  const [navIsOpen, setNavIsOpen] = useState(false)
  const [confirmedAge, setConfirmedAge] = useState(undefined)

  const navRef = useRef()
  navRef.current = navBackground

  useEffect(() => {
    setConfirmedAge(localStorage.getItem("isOfAge"))

    const handleScroll = () => {
      const show = window.scrollY > 70
      if (navRef.current !== show) {
        setNavBackground(show)
      }
    }
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const renderShoutBanner = () => (
    <span className="is-hidden-touch">
      <DesktopShoutBanner />
    </span>
  )

  const handleAgeRestrictionDisplay = () => {
    if (confirmedAge !== "true") {
      return (
        <AgeConfirmation
          onClick={setConfirmedAge}
          confirmedAge={confirmedAge}
        />
      )
    } else return
  }

  return (
    <>
      <SEO siteMetaData={siteMetaData} pageTitle={pageTitle} />
      {handleAgeRestrictionDisplay()}

      <main>
        {hasTopShoutBanner && renderShoutBanner()}

        <Header className={`${navBackground ? "scrolled" : ""}`}>
          <HeaderLeft>
            <span className="is-hidden-touch">
              <Link to="/">
                <img
                  src={cloudinaryOptimize(
                    "https://res.cloudinary.com/gonation/image/upload/v1604674017/sites/dockside-brewing/dockside-logo-white-R.png",
                    175
                  )}
                  alt="Dockside Brewing"
                />
              </Link>
            </span>

            <Link to="/" className="mobile-logo">
              <img
                src={cloudinaryOptimize(
                  "https://res.cloudinary.com/gonation/image/upload/v1604674019/sites/dockside-brewing/dockside-logo-R.png",
                  230
                )}
                alt="Dockside Brewing"
              />
            </Link>
          </HeaderLeft>
          <HeaderRight className="headerRight">
            <Navigation />
            {cta ? (
              <span
                style={{
                  textDecoration: "none",
                  textTransform: "uppercase",
                  color: "rgb(0,86,125)!important",
                  fontSize: ".75rem",
                }}
                className="cta-box"
              >
                <a target="_blank" rel="noopener noreferrer" href={`/${cta}`}>
                  View Specials
                </a>
              </span>
            ) : (
              <>
                {/* <a
                  className="cta-box"
                  href="/reserve-an-igloo"
                  style={{marginRight: '1rem', }}
                >
                  <span className="is-uppercase">Reserve Igloo</span>
                </a> */}
                <a
                  href="/reservations"
                  className="cta-box reserve-table reserve-table-pulse hide-on-mobile" // Added 'reserve-table-pulse' class
                  style={{
                    display: "none",
                  }}
                >
                  <span className="is-uppercase">Reserve Table</span>
                </a>

                <a
                  href="https://www.opentable.com/r/dockside-brewery-milford?shareReferrer=ios-share"
                  target="_blank"
                  rel="noreferrer"
                  className="cta-box hide-desktop"
                >
                  <span className="is-uppercase">Reserve Cabana</span>
                </a>
              </>
            )}
          </HeaderRight>
        </Header>

        <NavigationWrapper
          className={`is-hidden-desktop ${
            navIsOpen ? "" : "is-hidden-mobile is-hidden-tablet"
          }`}
        >
          <Navigation isMobile />
        </NavigationWrapper>

        {noNav ? (
          ""
        ) : (
          <CTAWidget
            siteMetaData={siteMetaData}
            navIsOpen={navIsOpen}
            setNavIsOpen={setNavIsOpen}
          />
        )}

        {pageTitle !== "home" && !noHero ? (
          <Hero
            pageHero={pageHero}
            pageTitle={pageTitle}
            subHeading={subHeading}
            hcHero={hcHero}
          />
        ) : (
          ""
        )}

        {children}

        {noFooter ? "" : <Footer data={siteMetaData} />}
      </main>
    </>
  )
}

const Header = styled.header`
  background: white;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 10px 12px;
  padding-top: 20px;
  @media (max-width: 620px) {
    .cta-box.reserve-table {
      display: none;
    }
  }
  @media (min-width: 1024px) {
    padding: 0;
    position: fixed;
    top: 45px;
    left: 0;
    width: 100%;
    background: transparent;
    z-index: 200;
    &.scrolled {
      top: 0px;
      background: ${props => (props.theme.dark ? props.theme.dark : "black")};
      transition: all 0.3s;
      .navDesktop {
        padding-top: 0rem;
      }
      .cta-box {
        margin-top: 0rem;
      }
      img {
        max-width: 125px !important;
      }
    }
  }

  .column__center,
  .hamburger-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const NavigationWrapper = styled.nav`
  position: fixed;
  background: ${props => (props.theme.primary ? props.theme.primary : "black")};
  height: 100vh;
  width: 100%;
  z-index: 99;
  padding-top: 1.5rem;
`
const HeaderRight = styled.div`
.hide-desktop {
	  @media (min-width: 1024px) {
	display: none!important;
	  }
}
  display: flex;
  width: 150px;
  flex-grow: 1;
  .reserve-table-pulse {
    @media (min-width: 1024px) { // Apply only on desktop devices
      animation-name: pulseAnimation;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }
  }

  @keyframes pulseAnimation {
    0%, 100% {
      background-color: ${({ theme }) => theme.secondary};
    }
    50% {
      background-color: #someLighterColor; // Replace with a lighter version of the secondary color
    }
  }

  @media (min-width: 1024px) {
    align-items: center;
    width: auto;
    padding-right: 1rem;
  }
  .hamburger-box {
    display: flex;
    justify-content: center;
    flex: 1;
    @media (min-width: 1024px) {
      background: #000;
    }
  }
  .cta-box {
    display: flex;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    align-items: center;
    flex-grow: 0;
    padding:0.5rem 1rem;
    margin:0 0 0 auto;
    span {
      white-space: nowrap;
    };

    /* padding:1rem 2rem; */
    @media (min-width: 1024px) {
      margin:2rem 0 0 0;
      /* border: 3px solid
        ${props => (props.theme.primary ? props.theme.primary : "black")};
      background: ${props =>
        props.theme.primary ? props.theme.primary : "black"}; */

background-color: ${({ theme }) => theme.secondary};

animation-name: greenPulse;
  animation-duration: 5s;
  animation-iteration-count: infinite;
      padding: 0.75rem  1rem;
      border-radius: 4px;
      &:hover {
        background: transparent;
        transition: all 0.3s;
      }

        @keyframes greenPulse {
          from { background-color: #003952  }
          50% { background-color: #00567d  }
          to { background-color: #003952 }
        }
    }
    a {
      color: ${({ theme }) => theme.primary};

    }
    span {
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 1px;
      text-align: center;
      color: ${props => (props.theme.primary ? props.theme.primary : "black")};
      font-weight: 600;
      @media (min-width: 1024px) {
        color: white;
        font-size: 12px;
      }
    }


  }
`

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 1024px) {
    padding: 0.35rem 0 0 1rem;
  }
  img {
    max-width: 230px;
    @media (min-width: 1024px) {
      max-width: 95px;
    }
    @media (min-width: 1146px) {
      max-width: 195px;
    }
  }
  .mobile-logo {
    @media (min-width: 1024px) {
      display: none;
    }
  }
  a {
    color: white;
    font-size: 0.75rem;
  }
`

export default Layout
