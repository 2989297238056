import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
// import shortid from "shortid"
import { Box, NavLink } from "@theme-ui/components"
import slugify from "slugify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"

export default function DropdownNavItemV1({ navLinks, mobile = false }) {
  // ===== state =====
  const [dropdownOpen, toggleDropdown] = useState(false)

  // =====  variables transfrmations  =====

  const {
    ctaName,
    dropdownLinks,
    // ctaLink,
  } = navLinks

  const tagName = slugify(ctaName, { lower: true })
  const dropdownRef = useRef() // the ref is used so the event listener has access to the latest state
  dropdownRef.current = dropdownOpen

  return (
    <Box
      className="navItemDropdown"
      onMouseEnter={e => {
        toggleDropdown(true)
      }}
      onMouseLeave={event => {
        toggleDropdown(false)
      }}
    >
      <Box as="li" sx={styles.navLink}>
        <span
          href="javascript:void(0);"
          onClick={() => false}
          className="navItemLink"
        >
          {ctaName} <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </Box>
      {dropdownOpen && (
        <Box sx={styles.dropdownMenu}>
          {dropdownLinks.map((dropdownLink, index) => {
            const { ctaName, ctaLink, clickable } = dropdownLink

            return (
              <NavLink
                sx={styles.dropdownNavLink}
                as="li"
                key={ctaName + index}
              >
                {clickable ? (
                  <Link to={ctaLink}>{ctaName}</Link>
                ) : (
                  <span style={{ color: "white", fontSize: "0.85rem" }}>
                    {ctaName}
                  </span>
                )}
              </NavLink>
            )
          })}
        </Box>
      )}
    </Box>
  )
}

const styles = {
  navLink: {
    margin: "1rem 0.5rem",
    display: "flex",
    a: {
      span: {
        fontSize: ["1.25rem", "", "", "0.85rem", "0.85rem"],
      },
    },
    "a, span": {
      color: "white",
      textTransform: "uppercase",
      fontSize: ["1.25rem", "", "", "0.85rem", "0.85rem"],
      width: "100%",
      margin: "0rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontWeight: "600",
      svg: {
        marginLeft: "0.5rem",
      },
      ":hover": {
        flexDirection: "row",
        "::after": {
          content: "unset",
          display: "none",
          background: "red",
        },
      },
    },
  },
  dropdownMenu: {
    display: "flex",
    position: ["", "", "", "absolute"],
    flexDirection: "column",
    marginBottom: "1rem",
    zIndex: "5000",
  },

  dropdownNavLink: {
    margin: "0rem 0rem",
    backgroundColor: "primary",
    padding: "1rem",
    minWidth: "150px",
    a: {
      color: "white",
      textTransform: "uppercase",
      fontSize: ["1.25rem", "", "", "0.85rem"],
      width: "100%",
      display: "flex",
      margin: "0rem",
      lineHeight: "14px",
      alignItems: "left",
      svg: {
        marginLeft: "0.5rem",
      },
      ":hover": {
        opacity: "0.6",
        alignItems: "flex-start",
        "::after": {
          content: "unset",
          display: "none",
          background: "red",
        },
      },
    },
  },
}
