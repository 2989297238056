import React, { useState, useEffect } from "react"
import styled from "styled-components"
import axios from "axios"
import moment from "moment"
import Star from "./icons/star"
import logo from "../assets/dockside-blue.png"
let jsonpAdapter = require("axios-jsonp")

const DesktopShoutBanner = () => {
  const [shoutData, setShoutData] = useState(null)
  const [showShoutModal, setShowShoutModal] = useState(false)
  const shoutURL =
    "https://data.prod.gonation.com/profile/shoutsnew/bzn-yO3xgUsKQCS7GWg0Q2ewbQ"

  useEffect(() => {
    axios({
      url: shoutURL,
      adapter: jsonpAdapter,
    }).then(res => {
      setShoutData(res.data)
    })
  }, [])

  const getShoutImage = () =>
    `https://res.cloudinary.com/gonation/q_auto,f_auto,w_800/${shoutData.shout.image.image.cloudinaryId}.webp`

  const addHTTP = url => {
    var prefix = "https://"
    if (url.substr(0, prefix.length) !== prefix) {
      return prefix + url
    }
    return url
  }

  const renderCTAs = () => {
    const buttonNames = Object.keys(shoutData.shout.ctas)
    return buttonNames
      .filter(btn => btn !== "cta1" && btn !== "cta2")
      .map(button => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={addHTTP(shoutData.shout.ctas[button])}
          >
            {button}
          </a>
        )
      })
  }

  const renderShoutModal = () => {
    return (
      <ShoutModal className="modal is-active">
        <ModalContent>
          <CloseModal onClick={() => setShowShoutModal(false)}>
            close
          </CloseModal>
          <StarBg>
            <Star width="100%" fill={"#c3bdb718"} />
          </StarBg>
          <div className="columns is-vcentered">
            <div className="column">
              {shoutData.shout.image.isDefault ? (
                <img src={logo} alt="Dockside Brewing" />
              ) : (
                <img src={getShoutImage()} alt={shoutData.shout.text} />
              )}
            </div>
            <div className="column">
              <ShoutBusinessName>
                {shoutData.shout.business.name} <span>Recent Shout </span>
              </ShoutBusinessName>
              <ShoutMessage>{shoutData.shout.text} </ShoutMessage>
              <p>{shoutData.shout.ctas && renderCTAs()}</p>

              <p className="shout-date" style={{ fontWeight: "400" }}>
                Shouted {moment(shoutData.shout.createdAt).fromNow()}
              </p>
            </div>
          </div>
        </ModalContent>
      </ShoutModal>
    )
  }

  return (
    <>
      {showShoutModal && renderShoutModal()}
      <Banner onClick={() => setShowShoutModal(true)} className="banner">
        {shoutData ? (
          <>
            <p>
              {/* Recent shout from {shoutData.shout.business.name}{" | "} */}
              {shoutData.shout.text} | Shouted{" "}
              {moment(shoutData.shout.createdAt).fromNow()}
            </p>
          </>
        ) : (
          ""
        )}
      </Banner>
    </>
  )
}

export default DesktopShoutBanner

const Banner = styled.div`
  position: relative;
  background: #ffffff;
  /* background: ${props =>
    props.theme.light ? props.theme.light : "black"}; */
  color: ${props => (props.theme.primary ? props.theme.primary : "black")};
  text-align: center;
  font-size: 14px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background: ${props =>
      props.theme.primary ? props.theme.primary : "black"};
      color: #fff;
      transition: all .3s;
  }
`

const ShoutModal = styled.div`
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
`

const ModalContent = styled.div`
  background: rgb(221 216 206);
  border-radius: 13px;
  padding: 2rem;
  max-width: 1200px;
  position: relative;
  max-height: none;
  width: 1000px;
  overflow: hidden;
  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: underline;
  }
`

const StarBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: -1;
  pointer-events: none;
  svg {
    width: 500px;
  }
`
const CloseModal = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  color: black;
  text-decoration: underline;
  cursor: pointer;
  z-index: 9;
  &:hover {
    color: $secondary;
  }
`

const ShoutMessage = styled.p`
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-bottom: 2rem;
  color: #111;
  font-weight: 400;
`

const ShoutBusinessName = styled.h2`
  font-size: 2rem;
  color: ${({ theme }) => theme.secondary};
  margin-bottom: 2rem;
  font-weight: bold;
  span {
    font-weight: 400;
    font-size: 1.75rem;
  }
`
