import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Facebook from "../icons/facebook"
import Instagram from "../icons/Instagram"
import Twitter from "../icons/Twitter"
import Guy from "../icons/GoNationMan"

const SocialRow = styled.div`
  display: flex;
  a {
    margin-left: 3rem;
  }
`

const SocialIcons = ({ iconWidth = "30px", iconFill = "#fff" }) => {
  const { siteMetaData } = useStaticQuery(graphql`
    query SocialQuery {
      siteMetaData {
        links {
          facebook
          instagram
          twitter
          youtube
        }
        slug
      }
    }
  `)

  const getSocialIcon = social => {
    switch (social) {
      case "facebook":
        return <Facebook fill={iconFill} width={iconWidth} />
      case "instagram":
        return <Instagram fill={iconFill} width={iconWidth} />
      case "twitter":
        return <Twitter fill={iconFill} width={iconWidth} />
      default:
        return
    }
  }

  const renderSocialIcons = () => (
    <div>
      {Object.keys(siteMetaData.links).map(
        (social, idx) =>
          siteMetaData.links[social] && (
            <>
              <a style={idx === 0 ? {margin: 0} : {}}
                target="_blank"
                rel="noopener noreferrer"
                href={siteMetaData.links[social]}
              >
                {getSocialIcon(social)}
              </a>
            </>
          )
      )}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://gonation.com/place/${siteMetaData.slug}/pulse`}
      >
        <Guy color={iconFill} height={'29px'} width={iconWidth} />
      </a>
    </div>
  )

  return <SocialRow>{renderSocialIcons()}</SocialRow>
}

export default SocialIcons
