import React from 'react';

const Beers = ({fill="#fff", width="40px"}) => {
    return (
<svg 
    width={width}
	 viewBox="0 0 511.999 511.999" >
<g>
	<g>
		<path fill={fill} d="M511.311,356.224l-10.419-59.088c-4.309-24.437-27.702-40.811-52.131-36.502l-14.772,2.605l-1.394-7.905
			c15.875-13.362,24.389-34.448,20.519-56.393c-5.758-32.652-36.846-54.432-69.507-48.67c-4.626,0.815-9.108,2.19-13.417,4.108
			c-17.239-16.988-42.104-24.959-66.653-20.629c-19.352,3.412-36.248,14.33-47.477,29.762c-11.172-15.513-28-26.307-47.599-29.762
			c-24.157-4.261-48.729,3.774-65.833,20.79c-4.558-1.95-9.406-3.418-14.236-4.27c-32.583-5.748-63.762,16.088-69.507,48.67
			c-3.866,21.921,4.636,43.024,20.519,56.393l-1.394,7.905l-14.772-2.605c-24.487-4.322-47.811,12.006-52.13,36.502L0.693,356.225
			c-4.319,24.492,12.005,47.811,36.502,52.131l14.772,2.605l-2.605,14.772c-4.309,24.437,12.066,47.822,36.523,52.134L205.04,498.7
			c2.632,0.464,5.25,0.688,7.835,0.688c19.76-0.001,37.483-13.106,43.127-32.26c6.377,21.647,28.193,35.587,50.942,31.575
			l119.196-20.842c24.437-4.309,40.812-27.694,36.503-52.131l-2.605-14.772l14.771-2.604
			C499.245,404.045,515.619,380.659,511.311,356.224z M57.176,381.416l-14.772-2.604c-8.165-1.441-13.608-9.213-12.168-17.378
			l10.419-59.089c1.434-8.13,9.213-13.604,17.377-12.167l14.772,2.605C70.919,303.475,59.521,368.116,57.176,381.416z
			 M88.431,204.151c2.873-16.291,18.465-27.205,34.754-24.335c5.054,0.891,10.22,3.062,13.821,5.807
			c6.799,5.184,16.582,3.645,21.448-3.421c10-14.521,27.586-21.941,44.801-18.906c10.202,1.799,19.136,6.949,25.702,14.381
			c-29.251,8.261-48.127,37.589-42.738,68.15c0.365,2.072,0.84,4.106,1.407,6.102c-11.066-1.952-20.987-8.084-27.934-17.267
			c-2.477-3.274-6.187-5.39-10.266-5.854c-4.081-0.466-8.17,0.763-11.319,3.396c-6.806,5.69-16.519,8.257-25.343,6.702
			C96.438,236.025,85.553,220.482,88.431,204.151z M227.625,456.988c-1.437,8.146-9.234,13.6-17.398,12.164L91.073,448.318
			c-8.146-1.437-13.604-9.232-12.167-17.377c3.139-17.802,22.753-129.037,26.047-147.72c0-0.002,0-0.003,0.001-0.005l2.603-14.767
			c12.757,2.25,26.067,0.257,37.613-5.362c13.981,12.781,31.898,19.525,50.173,19.525c3.935,0,7.856-0.329,11.752-0.964
			c5.185,4.272,10.988,7.711,17.696,10.164l15.98,90.628L227.625,456.988z M215.762,240.615
			c-2.873-16.291,8.044-31.881,24.335-34.754c4.493-0.791,9.214-0.431,14.033,1.072c3.917,1.221,8.164,0.785,11.752-1.208
			c3.589-1.992,6.202-5.368,7.234-9.341c4.43-17.065,18.417-30.053,35.633-33.089c17.483-3.08,35.891,4.515,45.802,18.907
			c2.327,3.38,5.938,5.658,9.99,6.305c4.055,0.645,8.193-0.396,11.457-2.883c4.016-3.062,8.33-5.016,12.821-5.808
			c16.328-2.877,31.875,8.004,34.753,24.335c2.881,16.326-8.003,31.874-24.334,34.753c-8.571,1.509-17.442-0.931-24.344-6.702
			c-3.149-2.633-7.239-3.859-11.318-3.396c-4.079,0.465-7.789,2.581-10.266,5.854c-6.649,8.79-17.465,15.245-28.933,17.267
			c-11.068,1.948-22.486-0.419-32.154-6.672c-7.184-4.645-16.818-2.353-21.123,5.071c-4.513,7.783-12.015,13.113-20.584,14.624
			C234.228,267.819,218.635,256.906,215.762,240.615z M420.952,448.314l-119.196,20.842c-8.149,1.432-15.941-4.022-17.378-12.167
			l-28.652-162.493c12.548-2.212,23.919-8.403,32.638-17.533c13.03,5.364,27.269,6.969,41.223,4.509
			c14.248-2.512,27.569-9.017,38.093-18.386c11.341,5.635,24.179,7.582,36.767,5.362c5.339,30.28,21.983,124.666,28.653,162.492
			C434.535,439.086,429.077,446.882,420.952,448.314z M469.6,378.811l-14.771,2.604L439.2,292.783l14.771-2.604
			c8.144-1.433,15.94,4.022,17.377,12.168l10.419,59.088C483.204,369.58,477.746,377.375,469.6,378.811z"/>
	</g>
</g>
<g>
	<g>
		<path fill={fill} d="M256.002,12.609c-8.284,0-15,6.716-15,15v30c0,8.284,6.716,15,15,15s15-6.716,15-15v-30
			C271.002,19.324,264.286,12.609,256.002,12.609z"/>
	</g>
</g>
<g>
	<g>
		<path fill={fill} d="M337.214,51.395c-5.857-5.858-15.355-5.858-21.212,0l-21.213,21.213c-5.858,5.858-5.858,15.355,0,21.213
			c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394l21.213-21.213C343.072,66.75,343.072,57.253,337.214,51.395z"/>
	</g>
</g>
<g>
	<g>
		<path fill={fill} d="M217.214,72.608l-21.213-21.213c-5.857-5.858-15.355-5.858-21.212,0c-5.858,5.858-5.858,15.355,0,21.213l21.213,21.213
			c2.929,2.929,6.768,4.394,10.606,4.394c3.838,0,7.678-1.464,10.606-4.394C223.072,87.963,223.072,78.466,217.214,72.608z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

    );
}

export default Beers;
