import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import DesktopLogo from "../../assets/desktop-footer-logo.png"
import cloudinaryOptimize from "../../helpers/cloudinaryOptimize"
import SocialIcons from "../SocialIcons/SocialIcons"
import { withTheme } from "styled-components"

const FooterLogo = ({ name, theme }) => {
  return (
    <div>
      <LogoContainer>
        {/* Two different logos for mobile and desktop */}
        <LogoImageContainer className="is-hidden-desktop">
          <LogoImage
            src={cloudinaryOptimize(
              "https://res.cloudinary.com/gonation/image/upload/sites/dockside-brewing/footer-mobile.png",
              275
            )}
            alt="Dockside"
          />
        </LogoImageContainer>
        <LogoImageContainer className="is-hidden-touch">
          <Link to="/">
            <LogoImage
              src={cloudinaryOptimize(
                "https://res.cloudinary.com/gonation/image/upload/v1604674019/sites/dockside-brewing/dockside-logo-R.png",
                400
              )}
              alt="Dockside"
            />
          </Link>
        </LogoImageContainer>
      </LogoContainer>

      <Title>Let's get social</Title>
      <Center>
        <SocialIcons iconFill={theme.primary}></SocialIcons>
      </Center>
      <GonationLogo>
        <img
          src="https://www.gonationsites.com/GNSE/gn-sites/images/gn-power-black.svg"
          alt={name}
        />
      </GonationLogo>
    </div>
  )
}

const LogoContainer = styled.div`
  margin: 2rem 0;
  text-align: center;
  @media (min-width: 1024px) {
    max-width: 600px;
    margin: 2rem auto;
  }
`

const LogoImage = styled.img`
  max-width: 200px;
  @media (min-width: 1024px) {
    max-width: 500px;
  }
`

const LogoImageContainer = styled.div``

const Title = styled.h3`
  margin-top: 2rem;
  font-size: 1.75rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1.5rem;
  color: ${props => (props.theme.primary ? props.theme.primary : "black")};
`

const GonationLogo = styled.div`
  border-top: 3px solid $secondary;
  text-align: center;
  padding: 1rem 0;
  img {
    max-width: 200px;
  }
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`

export default withTheme(FooterLogo)
